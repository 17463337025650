import react from "react"

const Suple = () => (
    <>
        <h1 className="text-4xl mb-4">Suple</h1>
        <section className="mb-4">
            <h2 className="text-xl font-bold mb-2">Śniadanie</h2>
            <ul>
                <li>Witamina b12 - pod język</li>
                <li>Glutation: 1 kapsułka</li>
                <li>Berberyna: 1 kapsułka</li>
            </ul>
        </section>
        <section  className="mb-4">
            <h2 className="text-xl font-bold mb-2">Obiad</h2>
            <ul>
                <li>Witamina D3: 3000-5000 IU</li>
                <li>Witamina E: 1 kapsułka</li>
                <li>Cholina: 1 kapsułka</li>
            </ul>
        </section>
        <section  className="mb-4">
            <h2 className="text-xl font-bold mb-2">1.5h po obiedzie</h2>
            <ul>
                <li>Kwasy omega-3:  2 kapsułki</li>
            </ul>
        </section>
        <section  className="mb-4">
            <h2 className="text-xl font-bold mb-2">Kolacja</h2>
            <ul>
                <li>Witamina E: 1 kapsułka</li>
                <li>Witamina B4 cholina: 1 kapsułka</li>
            </ul>
        </section>
        <section  className="mb-4">
            <h2 className="text-xl font-bold mb-2">Przed snem</h2>
            <ul>
                <li>Ostropest plamisty (1 łyżka)</li>
                <li>Witamina B4 cholina: 1 kapsułka</li>
            </ul>
        </section>
    </>
)

export default Suple