
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Home from "./Home.js"
import Suple from "./Suple.js"

function App() {
  return (
    <Router>
    <div>
      <nav>
        <ul className="flex p-4">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/suple">Suple</Link>
          </li>
        </ul>
      </nav>
      <main className="p-4">
      {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
      <Switch>
        <Route path="/suple">
          <Suple />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
      </main>
    </div>
  </Router>
  );
}

export default App;
